/* ---------------------------------------------------------------------- */
/*  Forms
/* ---------------------------------------------------------------------- */
.form-control::-moz-placeholder {
    color: #c2c2c5;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #c2c2c5;
    opacity: 1;
}

.form-control::-webkit-input-placeholder {
    color: #c2c2c5;
    opacity: 1;
}

textarea, select, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #8f8f8f;
    border-radius: 3px;
    font-family: inherit;
    line-height: 1.2;
    padding: 5px 4px;
    box-shadow: none;
    transition-duration: 0.1s;
    transition: 300ms ease-in-out;
    height: calc(2.25rem + 2px);

    color: #373a4c;
    font-weight: 600;
    font-size: 12px;
}

textarea:hover, select:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="time"]:hover, input[type="week"]:hover, input[type="number"]:hover, input[type="email"]:hover, input[type="url"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="color"]:hover {
    border-color: #8f8f8f;
}

textarea:focus, select:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus,
.form-control:focus {
    box-shadow: none;
    border-color: #bbbac0;
    border-style: solid;
    background-color: #F8F8F8;
}

textarea[disabled], input[disabled], select[disabled] {
    background: #f7f7f8;
    border-color: #F8F8F8;
}

textarea[disabled]:hover, textarea[disabled]:focus, input[disabled]:hover, input[disabled]:focus, select[disabled]:hover, select[disabled]:focus {
    background: #f7f7f8;
    border-color: #F8F8F8;
}

.validation-invalid, .validation-valid {
    padding: 0 5px;
}

input.error,
select.error {
    box-shadow: 0 0 8px 0 #eca8a0;
    border-color: #cb0607;
}

label {
    color: #5a5959;
    font-size: 0.8rem;
    margin-bottom: 0.2em;
    font-weight: 400;
}

.form-control {
    color: inherit;
}

select.form-control {
    background-color: #FFFFFF;
    border: 1px solid #c8c7cc;
    border-radius: 0 0 0 0;
    color: #5b5b60;
}

select.form-control option {
    padding: 3px 4px;
}

textarea.autosize {
    min-height: 71px;
}

textarea.autosize.area-animated:focus {
    vertical-align: top;
    transition: height 0.2s;
    overflow: hidden;
    word-wrap: break-word;
    resize: horizontal;
}

.radio label.radio-inline, .checkbox label.checkbox-inline {
    display: inline-block;
}

.radio-inline, .radio-inline + .radio-inline, .checkbox-inline, .checkbox-inline + .checkbox-inline {
    margin: 5px 10px 10px 0;
}

.form-group {
    position: relative;
}

.form-group .text {
    display: block;
    font-weight: 400;
    margin-top: 7px;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    cursor: default;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    max-width: 50px;
}

input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    cursor: pointer;
    line-height: normal;
}

.clip-check {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0;

    i {
        color: #009fe3;
        margin-right: 0.5em;
    }
}

.clip-check label {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    margin-right: 15px;
    padding-left: 30px;
    position: relative;
    line-height: 23px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
    white-space: nowrap;
}

.clip-check label:before {
    background-color: #ffffff;
    border: 1px solid #c8c7cc;
    content: "";
    display: inline-block;
    height: 20px;
    left: 0;
    margin-right: 10px;
    position: absolute;
    width: 20px;
    border-radius: 0;
    top: 1px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
}

.clip-check label:after {
    display: inline-block;
    font-size: 11px;
    height: 19px;
    left: 4px;
    position: absolute;
    top: -1px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
    width: 19px;
}

.clip-check input[type="checkbox"] {
    display: none;
}

.clip-check input[type="checkbox"]:checked + label:before {
    border-width: 10px;
}

.clip-check input[type="checkbox"]:checked + label:after {
    color: #fff;
    content: "\f00c";
    font-family: "FontAwesome";
}

.clip-check input[type="checkbox"][disabled] + label {
    opacity: 0.65;
}

.clip-check input[type="checkbox"][disabled] + label:before {
    background-color: #F8F8F8;
}

.clip-check.check-sm label {
    margin-right: 15px;
    padding-left: 24px;
    line-height: 22px;
}

.clip-check.check-sm label:before {
    height: 14px;
    width: 14px;
    top: 4px;
}

.clip-check.check-sm label:after {
    font-size: 11px;
    height: 13px;
    left: 2px;
    width: 13px;
}

.clip-check.check-sm input[type="checkbox"]:checked + label:before {
    border-width: 7px;
}

.clip-check.check-md label {
    margin-right: 15px;
    padding-left: 35px;
    line-height: 28px;
}

.clip-check.check-md label:before {
    height: 25px;
    width: 25px;
}

.clip-check.check-md label:after {
    font-size: 14px;
    height: 24px;
    left: 5px;
    width: 24px;
}

.clip-check.check-md input[type="checkbox"]:checked + label:before {
    border-width: 12px;
}

.clip-check.check-lg label {
    margin-right: 15px;
    padding-left: 40px;
    line-height: 33px;
}

.clip-check.check-lg label:before {
    height: 30px;
    width: 30px;
}

.clip-check.check-lg label:after {
    font-size: 17px;
    height: 29px;
    left: 6px;
    width: 29px;
}

.clip-check.check-lg input[type="checkbox"]:checked + label:before {
    border-width: 15px;
}

.clip-check.check-success input[type="checkbox"]:checked + label:before {
    border-color: #5cb85c;
}

.clip-check.check-primary input[type="checkbox"]:checked + label:before {
    border-color: #007AFF;
}

.clip-check.check-warning input[type="checkbox"]:checked + label:before {
    border-color: #eea236;
}

.clip-check.check-danger input[type="checkbox"]:checked + label:before {
    border-color: #d43f3a;
}

.clip-check.check-info input[type="checkbox"]:checked + label:before {
    border-color: #46b8da;
}

.clip-check.check-purple input[type="checkbox"]:checked + label:before {
    border-color: #804C75;
}

.clip-radio {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    font-size: 13px;
    color: #495057;
    vertical-align: middle;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-size: 25px 25px;
    border: 1px solid #ced4da;
    cursor: pointer;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-row > .col, .form-row > [class*=col-] {
    padding-right: 1em;
}

.help-block {
    color: #e2115d;
    font-size: 0.75rem;
}

fieldset {
    position: relative;
    transition: all .63s ease;
    margin: 1em 0;
    padding: 1em 1em 0;
    height: auto;
    width: 50%;
    background: #ffffff;
    border: 1px solid #e6e8e8;
    border-radius: 5px;

    &:hover {
        box-shadow: 0 0 15px 0 #d8d8d8;
    }

    .form-group {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5em;
    }

    legend {
        font-size: 14px;
        font-weight: 100;
        color: #5073aa;
        background: inherit;
        left: 10px;
        padding: 0 10px;
        position: absolute;
        top: -12px;
        width: auto;
        border: none;
    }
}

::ng-deep .form-group.icon {
    i {
        position: absolute;
        /* top: 0; */
        bottom: 10px;
        margin: auto;
        left: 15px;
        color: #81a1ff;
    }

    input {
        padding-left: 35px;
    }

}
