/* You can add global styles to this file, and also import other style files */
@import "globals";
@import "forms";

body {
    font-family: 'Open Sans', sans-serif ;
    overflow: hidden;
    font-size: 1rem;
}

h1 {
    font-size: 1.2rem;
    font-weight: bold;
}

input:focus,
li:focus,
a:focus {
    background-color: #f8f8fa !important;
}

button:focus,
input:focus,
li:focus,
a:focus,
.row:focus {
    outline: none !important;
    transition: background-color .3s ease !important;
    box-shadow: none !important;
}

button {
    padding: 0.75em;
}

.btn {
    transition: all .5s ease;
    padding: 0.75em;
}

.btn:hover {
    border-radius: 0.5em;
}

.btn-primary.focus,
.btn-primary:focus {
    outline: none;
    box-shadow: none;
}

small.error {
    padding: 0.1em;
    width: 100%;
    display: flex;
    color: #bd0000;
    background: transparent;
    border: 0;
    transition: all .3s ease;
}

html, body {
    height: 100%;
}

.pac-item {
    padding: 0.5em;
    text-align: left;
    align-items: center;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #28a745;
}


::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #747474;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #6e6e6e;
}

::-webkit-scrollbar-thumb:active {
    background: #535353;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #c9c9c9;
}

::-webkit-scrollbar-track:active {
    background: #ffffff;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

button.btn {
    background: $blue;
    color: white;
    border: none;
    margin: 0.5em;
    padding: 0.5em;
    font-size: 0.85rem;
    min-width: 7vw;
    border-radius: 0;
    font-weight: 500;
    transition-duration: 0.1s !important;
    transition: 300ms ease-in-out !important;

    &.btn-danger {
        background: white;
        color: red;
        border-bottom: 1px dashed red;
        margin-right: 2em;

        &:hover {
            background: $red;
            color: white;
        }
    }

    &:hover {
        background: #00ADF5;
        box-shadow: 0 0 8px 0 rgb(145, 149, 169);
    }

    i {
        margin-right: 0.5em;
    }
}


