$color-shadow: #777;
.effect,
.hovered-effect:hover {
    position: relative;

    &:before, &:after {
        z-index: -1;
        position: absolute;
        content: '';
        bottom: 1em;
        width: 50%;
        top: 80%;
        background: $color-shadow;
        box-shadow: 0 1em .5em $color-shadow;
        transition: opacity 0.3s ease-in-out;
    }

    &:before {
        transform: rotate(-3deg);
        left: .5em;
    }

    &:after {
        transform: rotate(3deg);
        right: .5em;
    }
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 0;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: transparent;
    --sk-color: #009fe3;
    --sk-size: 50px;
}
